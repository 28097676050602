import React from 'react'
import './about.css'
import ME from "../../assets/me2.jpg";
import {FaAward} from 'react-icons/fa'
import { FiUsers } from "react-icons/fi";
import { MdOutlineLibraryBooks } from "react-icons/md";


const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon"></FaAward>
              <h5>Experience</h5>
              <small>4+ Years Coding</small>
            </article>

            <article className="about__card">
              <FiUsers className="about__icon"></FiUsers>
              <h5>Clients</h5>
              <small>15+ Worldwide</small>
            </article>

            <article className="about__card">
              <MdOutlineLibraryBooks className="about__icon"></MdOutlineLibraryBooks>
              <h5>Projects</h5>
              <small>10+ Completed</small>
            </article>
          </div>
          <p>
            I am a full stack web developer capable of creating MERN
            applications. I also have 6+ years of design and UX management. As
            an added bonus I am proficient in Adobe Photoshop, InDesign,
            Illustrator, and XD.
          </p>
          <div className="about__btn">
            <a href="#contact" className="btn btn-primary">
              Let's Talk
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About
