import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/c1.jpg'
import AVTR2 from '../../assets/c2.jpg'
import AVTR3 from '../../assets/c3.jpg'
import AVTR4 from "../../assets/c4.jpg";

// swiperjs.com
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const data = [
  {
    avatar: AVTR1,
    name: "Name one",
    review:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Facilis corporis reiciendis blanditiis repudiandae, alias ab.",
  },
  {
    avatar: AVTR1,
    name: "Name two",
    review:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Facilis corporis reiciendis blanditiis repudiandae, alias ab.",
  },
  {
    avatar: AVTR1,
    name: "Name three",
    review:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Facilis corporis reiciendis blanditiis repudiandae, alias ab.",
  },
];

const Testimonials = () => {
  return (
    <section id="testimonials">
      <h5>Review from clients</h5>
      <h2>Testimonials</h2>
      <Swiper
        className="container testimonials__container"
        // install Swiper modules
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        navigation
        spaceBetween={50}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {data.map((obj) => (
          <SwiperSlide className="testimonial">
            <div className="client__avatar">
              <img src={obj.avatar} alt={obj.name} />
            </div>
            <h5 className="client__name">{obj.name}</h5>
            <small className="client__review">{obj.review}</small>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
}

export default Testimonials
