import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/nextjs-portfolio.png'
import IMG2 from '../../assets/content-mangement-wp.png'
import IMG3 from '../../assets/ecommerce-backend.png'
import IMG4 from '../../assets/ecommerce-frontend.png'
import IMG5 from '../../assets/calph-donation.png'
import IMG6 from "../../assets/ecommerce-management.png";

// Image size 1000px * 900px
const data = [
  {
    id: 6,
    image: IMG6,
    title: "Product Management Console",
    github: "https://github.com/yhlee-dho/commerce-keystone-frontend",
    demo: "#portfolio",
  },
  {
    id: 5,
    image: IMG5,
    title: "Donation Website with Shopify",
    github: "https://github.com/yhlee-dho/Calph_Donation",
    demo: "#portfolio",
  },
  {
    id: 4,
    image: IMG4,
    title: "E-Commerce frontend",
    github: "https://github.com/yhlee-dho/commerce-keystone-frontend",
    demo: "https://commerce.yhlee.io",
  },
  {
    id: 3,
    image: IMG3,
    title: "E-Commerce backend",
    github: "https://github.com/yhlee-dho/commerce-keystone-backend",
    demo: "https://api.yhlee.io",
  },
  {
    id: 2,
    image: IMG2,
    title: "Content Management on Wordpress",
    github: "#portfolio",
    demo: "https://www.chicagokoreatimes.com/wp-admin",
  },
  {
    id: 1,
    image: IMG1,
    title: "Portfolio with Next-Tailwind",
    github: "https://github.com/yhlee-dho/next-tailwind-portfolio",
    demo: "https://next-tailwind-landing.vercel.app/",
  },
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">
        {data.map((project) => (
          <article className="portfolio__item" key={project.id}>
            <div className="portfolio__item-image">
              <img src={project.image} alt={project.title} />
            </div>

            <h3>{project.title}</h3>
            <div className="portfolio__item-cta">
              <a
                href={project.github}
                className="btn"
                target="_blank"
                rel="noreferrer"
              >
                Github
              </a>
              <a
                href={project.demo}
                className="btn btn-primary"
                target="_blank"
                rel="noreferrer"
              >
                Live Demo
              </a>
            </div>
          </article>
        ))}
      </div>
    </section>
  );
}

export default Portfolio
