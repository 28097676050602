import React from 'react'
import './services.css'
import { BiCheck } from "react-icons/bi";

const Services = () => {
  return (
    <section id="services">
      <h5>What I offer</h5>
      <h2>Services</h2>
      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>UI/UX Design</h3>
          </div>
          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Interaction Design</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Wireframes</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Prototype</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>A/B Testing</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Information Architecture</p>
            </li>
          </ul>
        </article>

        <article className="service">
          <div className="service__head">
            <h3>Web Development</h3>
          </div>
          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Responsive Development</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Web Analysis and Interpretation</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Competition Monitoring</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Site Customization/Management</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>PPC Campaign Management</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Usability Testing</p>
            </li>
          </ul>
        </article>

        <article className="service">
          <div className="service__head">
            <h3>Content Creation</h3>
          </div>
          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Graphic Design</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Blog Writing</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Case Studies</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Infographics</p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  );
}

export default Services
